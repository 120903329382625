* {
  margin: 0;
  padding: 0;
}
section {
  padding-left: 10%;
}
.home h1 {
  font-size: 30px;
  font-family: sans-serif;
  margin-top: 10vh;
}
.home h2 {
  font-size: 25px;
  font-family: sans-serif;
  margin-top: 20px;
}
.home a {
  font-family: sans-serif;
  text-decoration: none;
  color: #111;
  font-size: 23px;
  position: absolute;
  bottom: 10vh;
}
